$(() => {

  // Add a body class if the shop items are in view
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // Get the value of "k" in eg "https://alme-tnew-test.tnhs.cloud/events?k=aux&view=list"
  let value = params.k; // "k"

  if (value === "aux") {
    document.body.classList.add("state--aux-view");
  } else {
    document.body.classList.remove("state--aux-view");
  }

})

if (window.location.href.includes('account/create/brief')) {
  checkContactPrefLoaded(document.getElementsByTagName('contact-permissions-app')[0], function() {
    document.querySelector('.tn-component-contact-permissions__header h2').innerHTML = 'I want to hear about:';
  })
}

function checkContactPrefLoaded (contactPrefs, callback) {
  if (contactPrefs.innerHTML.length > 0) {
    callback()
  } else {
    // Hasn't loaded so wait and try again
    setTimeout(() => {
      checkContactPrefLoaded(contactPrefs, callback)
    }, 100)
  }
}
